<template>
  <Modal v-model="isShow" title="编辑物流公司" width="800" class-name="i-modal">
    <Form
      :model="obj"
      class="modelViewFrom"
      ref="form"
      :rules="validateRules"
      :label-width="95"
    >
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="Icon" label="物流Logo">
            <i-upload
              :fileKey="UploadType.LogisticsLogo"
              @onChange="onChange"
              ref="UploadLogistic"
            ></i-upload>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="8">
          <FormItem prop="Code" label="物流公司编号">
            <Input
              type="text"
              v-model="obj.Code"
              :disabled="!isEdit && !isAdd"
              placeholder="请输入物流公司编号"
            ></Input>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem prop="Name" label="物流公司名称">
            <Input
              type="text"
              v-model="obj.Name"
              :disabled="!isEdit && !isAdd"
              placeholder="请输入物流公司名称"
            ></Input>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem prop="ContractTel" label="联系座机">
            <Input
              type="text"
              v-model="obj.ContractTel"
              :disabled="!isEdit && !isAdd"
              placeholder="请输入联系座机"
            >
            </Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="8">
          <FormItem prop="Website" label="官方网站">
            <Input
              type="text"
              v-model="obj.Website"
              :disabled="!isEdit && !isAdd"
              placeholder="请输入官方网站"
            >
            </Input>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem prop="Index" label="排序">
            <Input
              type="text"
              v-model="obj.Index"
              :disabled="!isEdit && !isAdd"
              placeholder="请输入排序"
            >
            </Input>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem prop="Remark" label="状态">
            <i-select
              transfer
              v-model="obj.Status"
              clearable
              placeholder="状态"
            >
              <i-option :value="10">启用</i-option>
              <i-option :value="20">禁用</i-option>
            </i-select>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="8">
          <FormItem prop="CreateDate" label="创建时间">
            <DatePicker
              type="date"
              v-model="obj.CreateDate"
              disabled
              style="width: 100%"
            ></DatePicker>
          </FormItem>
        </Col>
      </Row>
      <!-- <Row :gutter="16">
        <Col span="8">
          <FormItem prop="ID" label="自增ID">
            <Input type="text" v-model="obj.ID" disabled> </Input>
          </FormItem>
        </Col>
      </Row> -->
    </Form>
    <div slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button
        v-if="isEdit || isAdd"
        type="primary"
        @click="handleSave"
        :loading="SaveLoading"
      >
        {{ !SaveLoading ? "保存" : "保存中..." }}
      </Button>
    </div>
  </Modal>
</template>
<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import iUpload from "@/components/upload/index.vue";
export default {
  name: "express-edit",
  data() {
    return {
      isShow: false,
      isEdit: false,
      isAdd: true,
      SaveLoading: false,
      loading: false,
      files: [],
      obj: {},
      validateRules: {
        Code: [
          { required: true, message: "物流公司编号不能为空", trigger: "blur" },
        ],
        Name: [
          { required: true, message: "物流公司名称不能为空", trigger: "blur" },
        ],
        Status: [{ required: true, message: "状态不能为空" }],
      },
    };
  },
  methods: {
    onChange(opt) {
      this.files = opt.files;
    },
    async handleSave() {
      const _this = this;
      try {
        _this.$refs.form.validate(async (valid) => {
          if (valid) {
            if (_this.files.length <= 0) {
              _this.$Message.warning("请上传图标");
              return;
            }
            _this.SaveLoading = true;
            const res = await _this.$refs.UploadLogistic.Upload();
            if (res.success.length !== 0) {
              _this.obj.Logo = res.success[0];
              const response = await api.SaveExpress({ entity: _this.obj });
              if (response.Status === 100) {
                _this.$Message.success("保存成功！");
                this.$emit("on-save", response.Data);
                _this.SaveLoading = false;
                _this.isShow = false;
              } else {
                _this.SaveLoading = false;
                _this.isShow = false;
                _this.$Message.error(response.Msg);
              }
            } else {
              _this.$Message.error("Logo上传失败");
              this.SaveLoading = false;
            }
          } else {
            _this.$Message.error("填写信息有误！");
          }
        });
      } catch (error) {
        _this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.SaveLoading = false;
      }
    },
  },
  watch: {
    isShow: function (val) {
      if (val && this.obj.ID && this.obj.Logo) {
        let files = [
          {
            _key: this.obj.Logo,
            _file: this.$setImagePrefix(this.obj.Logo),
            success: true,
          },
        ];
        this.files = this.$refs.UploadLogistic.uploadFiles = files;
      } else {
        this.files = [];
        this.$refs.UploadLogistic.uploadFiles = [];
      }
    },
  },
  computed: {
    UploadType() {
      return bizEnum.UploadType;
    },
  },
  components: { iUpload },
};
</script>
